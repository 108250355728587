<template>
    <v-text-field
        class="fancy"
        v-bind="{ ...$attrs, ...commonAttrs }"
        v-on="$listeners"
    >
        <template v-slot:append v-if="unit"> {{ unit }} </template>
        <template
            v-for="(_, scopedSlotName) in $scopedSlots"
            #[scopedSlotName]="slotData"
        >
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-text-field>
</template>

<script>
export default {
    name: 'FancyInput',
    inheritAttrs: false,
    props: {
        unit: {
            type: String,
            default: '',
        },
        ps: {
            type: String,
            default: '',
        },
    },
    computed: {
        commonAttrs() {
            return {
                outlined: true,
                shaped: true,
            };
        },
    },
    mounted() {
        // console.log(this);
    },
};
</script>

<style lang="scss">
div.fancy {
    &.v-text-field {
        &--filled {
            &.no_border {
                .v-input__control {
                    > .v-input__slot {
                        background-color: #f9f6f4 !important;
                        box-shadow: none !important;
                        align-items: top;
                        fieldset {
                            color: #f9f6f4 !important;
                        }
                        .v-label {
                            line-height: 21px !important;
                        }
                        .v-label--active {
                            color: #f9f6f4 !important;
                        }
                        .v-input__append-inner {
                            // margin-top: 16px !important;
                            color: #999;
                        }
                    }
                }
            }
        }
        &.v-text-field--solo {
            .v-input__append-inner {
                align-self: flex-start !important;
            }
        }
    }
}
.v-messages,
.v-text-field__details {
    min-height: fit-content;
}
</style>
